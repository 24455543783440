div.signInFormHolder {
  position: relative;
  margin: auto;
  display: inline-block;
  left: 41px; 
  bottom: 0; 
  right: 0;
  height: 350px;
  width: 328px;
  text-align: center;
}

div.errorHolder {
  position: relative;
  top: 50px;
}

div.errorHolderPassword {
  position: relative;
  top: -5px;
}

div.signInFormHolderNewPassword {
  position: relative;
  margin: auto;
  display: inline-block;
  top: 50px; 
  left: 41px; 
  bottom: 0; 
  right: 0;
  height: 580px;
  width: 328px;
  text-align: center;
  margin-bottom: 50px;
}